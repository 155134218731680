import React from "react";
import CtaBanner from "../Header/CtaBanner";
import LocationDisclaimer from "../../components/LocationDisclaimer/LocationDisclaimer";
const classes = require('./Footer.module.scss');
// const symbol = require('../../assets/images/fmbrSymbol.png');
const logo = require('../../assets/images/fmbrLogo-round.png');

const footer = () => {
  return (
    <div className={classes.Footer}>
      
      {/* <div className={classes.hcTop}><p>Call today to order: <Phone /></p></div> */}
      <CtaBanner />
      <div className={classes.heroCard}>
        <div className={classes.insideNrw}>
          <div className={classes.contentBox}>
            <div className={classes.image}><img src={logo} alt="Ft Morgan Beach Rentals logo. Delivering Gulf Coast beach supplies." className={classes.imgResponsive} /></div>
            <div className={classes.text}>
              <p>From beach chairs and shade, to sporting goods and activities, we provide you with everything you need to complete your beach vacation...plus free delivery!</p>
                <p className={classes.ldText}>
                  <LocationDisclaimer where="footer" />
                </p>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.copyHs}>
        <div className={classes.inside}>
          <p className={classes.copy}>© {(new Date().getFullYear())} Fort Morgan Beach Rentals. All rights reserved.</p> 
          <p className={classes.hs}>Design by <a href="http://hsdesignhouse.com" target="_blank" rel="noopener noreferrer">High Seas Design House.</a></p>
        </div>
      </div>
    </div>
  );
}

export default footer;





