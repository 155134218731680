import React from "react";
import { Link } from "react-router-dom";
import Weather from "./Weather";
import Drawer from "./Drawer";
const classes = require('./Header.module.scss');
const logo = require('../../assets/images/fmbrLogo.png');

const header = () => {
  return (
    <header className={classes.Header}>
      <div className={classes.headWrap}>
        <nav className={[classes.navMm, "xuk-navbar-container"].join(' ')}>
          <Link to="/" className={[classes.logoBlk, "xuk-navbar-item xuk-logo"].join(' ')}><img src={logo} alt="Ft Morgan Beach Rentals Logo" className={[classes.imgResponsive, classes.ilLogo].join(' ')} /></Link>
          
          <ul className={[classes.navBarCustom, "xuk-navbar-nav"].join(' ')}>
            <li className={classes.navItem}><Link to="/">Home</Link></li>
            <li className={classes.navItem}><Link to="/rentals">Products &amp; Delivery</Link></li>
            <li className={classes.navItem}><Link to="/contact">Contact Us</Link></li>
            <li className={classes.navItem}><Link to="/faqs">FAQ's</Link></li>
          </ul>
        
          <Weather />
          <div className={classes.mobNav}><Drawer /></div>
        </nav>        
      </div>
    </header>
  );
}

export default header;


