import React from "react";
const classes = require('./Social.module.scss');

const social = (props) => {
  return (
    <ul className={[classes.Social, classes[props.where]].join(' ')}>
      {/* <li><a href="https://www.facebook.com/FortMorganKayakAdventures/" target="_blank" rel="noopener noreferrer">
        <span className={classes.SocialIcon} aria-label="Ft Morgan Beach Rentals Facebook Link" uk-icon="icon: facebook"></span>
      </a></li> */}

      <li><a href="https://www.instagram.com/ft.morgan_beach_rentals/" target="_blank" rel="noopener noreferrer">
        <span aria-label="Ft Morgan Beach Rentals Instagram Link" uk-icon="icon: instagram"></span>
      </a></li>

      {/* <li><a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
        <span aria-label=Ft Morgan Beach Rentals Twitter Link" uk-icon="icon: twitter"></span>
      </a></li> */}
    </ul>
  );
};

export default social;
