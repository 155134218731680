import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import classes from "./Home.module.scss";
import Phone from "../../components/Shared/Phone";
import Period from "../../components/Shared/Period";
// import Audio from "../../components/Audio/Audio";
import DeliveryInfo from "../../components/DeliveryInfo/DeliveryInfo";
import LocationDisclaimer from "../../components/LocationDisclaimer/LocationDisclaimer";
// import disc from "../../assets/images/fmbr-disc.png";
const luggage = require('../../assets/images/road-trip.png');
const hobbies = require('../../assets/images/homeHobbiesBg.png');
const loungers = require('../../assets/images/homeLoungeBg.png');
const extras = require('../../assets/images/homeOtherBg.png');

const home = () => {
  return (
    <div className={classes.Home}>
      <Helmet>
        <title>Beach Activity & Equipment Rentals in Fort Morgan, Alabama</title>
        <meta name="description" content="Fort Morgan Beach Rentals delivers everything you need to complete your beach vacation - from beach chairs and shade, to sporting goods and activities." />
      </Helmet>

      <div className={[classes.insideN, classes.heroWrap].join(' ')}>
        <div className={classes.fmbHero}>
          <div className={classes.titleBox}>
            <h1>Fort Morgan <br/><b>Beach Rentals</b></h1>
            <p><Period /> Rentals</p>
            <h2>From beach chairs and shade, to sporting goods and activities, we provide you with everything you need to complete your beach vacation... 
              <span>plus free delivery!</span> </h2>
            
            <div className={classes.tbCTA}>
              <h3>Call Us Today: <Phone /></h3>
              <Link to="/rentals" className={classes.fmLinkAlt}>Reserve Online</Link>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.fmbhProducts}>
        <div className={classes.med2Def}>
          <div className={classes.top}>
            <h3>We've got you <span>covered</span></h3>
            <p>Let us lighten your load, and double your leg room!</p>
          </div>

          <div className={classes.hlf1}>
            <div className={classes.row}> 
              <h4>How does it work?</h4>
              <p>Choose what you need from our selection of products, provide the dates of your trip and other requested information, and we'll do the rest.
                Our team will deliver your items, and pick everything up at the end of your stay.<br/>
              <LocationDisclaimer where="homeLd" />
              </p>
            </div>
            
            <div className={classes.row}> 
              <h4>When will my items arrive?</h4>
              <p><DeliveryInfo where="homepage" /></p>
            </div>

            <div className={classes.row}> 
              <h4><span className={classes.timepd}>Online reservations are for <Period /> periods. </span></h4>
            </div>

            <div className={classes.spacer3}></div>
            <div className={classes.clearfix}></div>
            <Link to="/rentals" className={classes.fmLinkAlt}>View Products</Link>

            <Link to="/faqs" className={classes.fmLinkY}>More FAQ's</Link>
          </div>

          <div className={classes.hlf2}>
            <img src={luggage} alt="Couple with luggage for Fort Morgan, Alabama vacation" className={[classes.imgResponsive, classes.img].join(' ')} />
          </div>
        </div>
      </div>

      <div className={classes.booqTop}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
          <polygon fill="#64bcc7" points="0,100 100,0 100,100"/>
        </svg>
      </div>
      <div className={classes.homeCatGrid}>      
        <div className={classes.medDef}>
          <p className={classes.title}>Everything you need</p>

          <Link to="/rentals" className={[classes.catItem, classes.loungers].join(' ')}>
            <img src={loungers} alt="Beach chair rentals for Fort Morgan Alabama" className={[classes.imgResponsive, classes.img1].join(' ')} />
            <p>To relax <span data-uk-icon="arrow-right"></span></p>
          </Link>

          <Link to="/rentals" className={[classes.catItem, classes.hobbies].join(' ')}>
            <img src={hobbies} alt="Kayak rentals for the Gulf Coast area" className={[classes.imgResponsive, classes.img2].join(' ')} />
            <p>To have fun <span data-uk-icon="arrow-right"></span></p>
          </Link>

          <Link to="/rentals" className={[classes.catItem, classes.extras].join(' ')}>
            <img src={extras} alt="Vacation supply rentals in Fort Morgan, Alabama" className={[classes.imgResponsive, classes.img3].join(' ')} />
            <p>To feel at home <span data-uk-icon="arrow-right"></span></p>
          </Link>
        </div>
      </div>


      <div className={classes.fmbhBeach}>
        <div className={classes.med2Def}><div className={classes.fmbInner}>
          <div className={classes.textHlfTop}>
            <h4>We love our beaches, and we know you do too!</h4>
            <p>We ask that you do your part to help keep them beautiful and clean while you are here. By taking a few simple steps, you can help preserve our beaches, and protect our local wildlife.</p>
          </div>
          <div className={classes.textHlfBtm}>
            <ul>
              <li>Don't litter! Throw away all of your trash, and don't leave any on the beach. </li>
              <li>Stack or remove your beach chairs at the end of each day, and pack up all toys and equipment. 
              Anything left on the beach overnight can be harmful to nesting sea turtles. </li>
              <li>Refill all holes at the end of each day. They can also be dangerous for both wildlife and people. </li>
              <li><a href="https://www.fortmorgancivic.org/Clean-Beach-Initiative.html" target="_blank" rel="noopener noreferrer" className={classes.textLink}>Click here </a> to learn more about the Fort Morgan Clean Beach Initiative.</li>
            </ul>

          </div></div>
        </div>
      </div>
    </div>
  );
}
export default home;