import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import { Link } from "react-router-dom";
const classes = require('./Header.module.scss');

function TemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={[classes.list, classes.SideNavList].join(' ')}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={classes.closeIcon}><ClearIcon /></div>
      <ul className={classes.SideNav}>
        <li className={classes.navItem}><Link to="/">Home</Link></li>
        <li className={classes.navItem}><Link to="/rentals">Products &amp; Delivery</Link></li>
        <li className={classes.navItem}><Link to="/contact">Contact Us</Link></li>
        <li className={classes.navItem}><Link to="/faqs">FAQ's</Link></li>
        {/* <li className={classes.navItem}><button type="button" uk-toggle="target: #offcanvas-faq">FAQ's</button></li> */}
      </ul>
    </div>
  );

  return (
    <div className={classes.NavDrawer}>
      <Button onClick={toggleDrawer('left', true)}>
        <div className={classes.mobileNavBtn}>
          <span />
          <span />
          <span />
        </div>
        {/* <span aria-label="Ft Morgan Beach Rentals Navigation Link" uk-icon="icon: menu" className={classes.mobileNavBtn}></span> */}
        {/* <MenuIcon /> */}
      </Button>
     
      <Drawer anchor="left" open={state.left} onClose={toggleDrawer('left', false)} className={classes.cNavDrawer}>
        {sideList('left')}
      </Drawer>
    </div>
  );
}

export default TemporaryDrawer;