import React from "react";
import classes from "./Header.module.scss";


const weather = () => {
  return (
    <div className={classes.Weather}>
      <div className={classes.weatherWidge}>
        <a className={["weatherwidget-io", classes.fmReport].join(' ')} 
          href="https://forecast7.com/en/30d23n88d02/fort-morgan/?unit=us" 
          data-label_1="Fort Morgan, AL" 
          data-label_2="Current Conditions" 
          data-mode="Current" 
          data-days="3" 
          data-theme="pure" 
          data-basecolor="rgba(255, 255, 255, 0)" 
          data-textcolor="#172d53" 
          data-highcolor="#4ab1be" 
          data-lowcolor="#172d53" 
          data-suncolor="#fec756" 
          data-raincolor="#172d53" 
        >Fort Morgan Weather</a>
      </div>
    </div>
  )
}
export default weather;