import React, {Component} from "react";
import Reggae from "../../assets/reggae_loop.mp3";
import classes from "../../App.module.scss";
// import Pause from '@material-ui/icons/Pause';
import Pause from '@material-ui/icons/VolumeOff';
import Play from '@material-ui/icons/PlayCircleFilledWhiteOutlined';

class Music extends Component {
  constructor(props) {
    super(props);
    this.state = {
      play: false,
      pause: true,
      fullPause: false,
    }
    this.url = [Reggae];
    this.audio = new Audio(this.url);
  }

  play = () => {
    this.setState({ play: true, pause: false, fullPause: false })
    this.audio.play();
  }

  pause = () => {
    this.setState({ play: false, pause: true, fullPause: true })
    this.audio.pause();
  }

  writeit = () => {
    // console.log('Full Pause state is: ' + this.state.fullPause);
    this.setState({ play: true, pause: false })
    if (this.state.fullPause === false) {
      this.audio.play();
    }
  }
  
  componentDidMount() {
    window.addEventListener("keydown", this.writeit);
    window.addEventListener("click", this.writeit);
    // window.removeEventListener(this.pause, this.writeit);
  }


  render() {
    return (
      <div className={classes.musicBtns}>
        <button onClick={this.play} className={classes.playBtn} aria-label="Play button for background music"><Play /></button>
        <button onClick={this.pause} className={classes.pauseBtn} aria-label="Mute button for background music" ><Pause /></button>
      </div>
    );
  }
}
export default Music;
