import React from "react";
import classes from "./Shared.module.scss";

const period = (props) => {
  return (
    <span className={[classes.tpd, classes[props.where]].join(' ')}>
      {props.message}
    </span>
  );
}
period.defaultProps = {
  message:"3-7 day"
};
export default period;