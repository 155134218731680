import React from "react";
import { Helmet } from "react-helmet";
import classes from "./FAQs.module.scss";
import Phone from "../../components/Shared/Phone";
import Period from "../../components/Shared/Period";
import DeliveryInfo from "../../components/DeliveryInfo/DeliveryInfo";
import LocationDisclaimer from "../../components/LocationDisclaimer/LocationDisclaimer";

const fAQs = () => {
  return (
    <div className={classes.faqsPage}>
      <Helmet>
        <title>FAQ's | Beach Supply Rentals in Ft Morgan, Alabama </title>
        <meta name="description" content="Read through the Fort Morgan Beach Rentals FAQ's for more information. Contact us today to reserve your beach chairs, umbrellas, kayaks, and more!" />
      </Helmet>

      <div className={classes.faqBody}>
        <div className={classes.med2Def}>
          <div className={classes.fullbox}>
            <div className={classes.titlebox}>
              <h1>Fort Morgan<br/> Beach Rentals FAQ's</h1>
            </div>
            
            <div className={classes.fullFaq}>
              <ul className={classes.faqList} data-uk-accordion>
                <li className={[classes.faqItem, "Xuk-open"].join(' ')}>
                  <button className={[classes.faqQuestion, "uk-accordion-title uk-light"].join(' ')}>How does it work?</button>
                  <div className={[classes.faqContent, "uk-accordion-content"].join(' ')}>
                    <p>Choose what you need from our selection of products, provide the dates of your trip and other requested information, and we'll do the rest. We
                      will set up your tent, and leave your other items under your house. <b>We pick everything up the morning of your checkout date.</b>
                    </p>
                  </div>
                </li>

                <li className={classes.faqItem}>
                  <button className={[classes.faqQuestion, "uk-accordion-title"].join(' ')}>Where do you offer your products?</button>
                  <div className={[classes.faqContent, "uk-accordion-content"].join(' ')}>
                    <p className={classes.ldText}><LocationDisclaimer where="faqLi" /></p>
                  </div>
                </li>

                <li className={classes.faqItem}>
                  <button className={[classes.faqQuestion, "uk-accordion-title uk-light"].join(' ')}>What is the cancellation/refund policy?</button>
                  <div className={[classes.faqContent, "uk-accordion-content"].join(' ')}>
                    <p><b>Cancellations within 7-days of reservation will <span className={classes.und}>not</span> be eligible for refund. </b> 
                    We do <b><span className={classes.und}>not</span></b> offer refunds due to weather.</p>
                  </div>
                </li>

                <li className={classes.faqItem}>
                  <button className={[classes.faqQuestion, "uk-accordion-title"].join(' ')}>When will my items arrive?</button>
                  <div className={[classes.faqContent, "uk-accordion-content"].join(' ')}>
                    <p>
                      <DeliveryInfo where="faq-modal" /> Orders placed after 3pm will be delivered by the next afternoon.
                    </p>
                  </div>
                </li>

                <li className={classes.faqItem}>
                  <button className={[classes.faqQuestion, "uk-accordion-title"].join(' ')}>Is there a delivery fee?</button>
                  <div className={[classes.faqContent, "uk-accordion-content"].join(' ')}><p>No! We offer FREE delivery and setup!</p></div>
                </li>

                <li className={classes.faqItem}>
                  <button className={[classes.faqQuestion, "uk-accordion-title uk-light"].join(' ')}>How long is my rental period?</button>
                  <div className={[classes.faqContent, "uk-accordion-content"].join(' ')}>
                    <p>When you book online, you have the option to select a <Period /> rental period. </p>
                    <Phone where="faq-modal" />
                  </div>
                </li>

                <li className={classes.faqItem}>
                  <button className={[classes.faqQuestion, "uk-accordion-title uk-light"].join(' ')}>What if I need an item that you don't have listed?</button>
                  <div className={[classes.faqContent, "uk-accordion-content"].join(' ')}>
                    <p>Give us a call! We still might be able to help.<br/> <Phone where="faq-modal" /></p>
                  </div>
                </li>

                <li className={classes.faqItem}>
                  <button className={[classes.faqQuestion, "uk-accordion-title uk-light"].join(' ')}>How will I know which beach chairs, tents, or umbrellas are mine?</button>
                  <div className={[classes.faqContent, "uk-accordion-content"].join(' ')}>
                    <p>Your tent will have a fluorescent piece of tape on the cross bar with your last name written on it. Your chairs & umbrellas will be delivered under your home.</p>
                  </div>
                </li>

                <li className={classes.faqItem}>
                  <button className={[classes.faqQuestion, "uk-accordion-title uk-light"].join(' ')}>What do I do with my tent if there is a storm, or the water is rising?</button>
                  <div className={[classes.faqContent, "uk-accordion-content"].join(' ')}>
                    <p>If you are experiencing strong winds or a storm, and your tent is at risk, please call us so we can take it down ASAP.
                      If the water is rising, and you need your tent moved to a higher area, give us a call.
                    </p>
                  </div>
                </li>
              
                <li className={[classes.faqItem, classes.pickup].join(' ')}>
                  <button className={[classes.faqQuestion, "uk-accordion-title "].join(' ')}><b>** Customers staying at The Dunes, The Indies, or Kiva Dunes</b></button>
                  <div className={[classes.faqContent, "uk-accordion-content"].join(' ')}>
                    <p>If you are staying at <b>The Dunes, The Indies, or Kiva Dunes,</b> please <Phone callText="call" where="color2" /> for pricing and reservations.</p>
                    <p>Orders for <b>The Dunes, The Indies, and Kiva Dunes</b> can be picked up on the beach, from the beach attendant, <b>between 8am-5pm.</b></p>
                  </div>
                </li>

                <li className={[classes.faqItem, classes.pickup].join(' ')}>
                  <button className={[classes.faqQuestion, "uk-accordion-title "].join(' ')}><b>** Morgantown Customers</b></button>
                  <div className={[classes.faqContent, "uk-accordion-content"].join(' ')}>
                    <p>We cannot provide <span className={classes.bu}>tents</span> to the Morgantown area. However, all other rental items are available.</p>
                  </div>
                </li>
              </ul>

              <div className={classes.notice}>
                <p>*Guests are responsible for damaged or lost equipment.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default fAQs;