import React from "react";
const classes = require('./DeliverInfo.module.scss');


const deliveryInfo = (props) => {
  return (
    <span className={[classes.DeliveryInfo, classes[props.where]].join(' ')}>
      Orders placed by 3pm on your check-in date are guaranteed to be delivered by 10am the morning after you check in. 
    </span>
  );
}
export default deliveryInfo;