import React from "react";
import classes from "./Shared.module.scss";

const phone = (props) => {
  return (
    <a className={[classes.callLink, classes[props.where]].join(' ')} href="tel: +12515977752">
      {props.callText ? [props.callText] : '251-597-7752'}
    </a>
  );
}
export default phone;