import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import CtaBanner from "../../components/Header/CtaBanner";
import LocationDisclaimer from "../../components/LocationDisclaimer/LocationDisclaimer";
const classes = require('./Rentals.module.scss');


const rentals = () => {
  return (
    <div className={classes.Rentals}>
      <Helmet>
        <title>Beach Equipment & Activity Rentals in Fort Morgan, Alabama | Fort Morgan Beach Rentals</title>
        <meta name="description" content="From beach chairs and shade, to kayaks and paddleboards, check out our beach supplies and activity rentals for the Fort Morgan, Alabama area. Free delivery!" />
      </Helmet>

      <CtaBanner />
      <div className={classes.introBlock}>
        <div className={classes.medDef}>
          <div className={classes.tbox}>
            <h1>Check Out Our Selection</h1>
            <h2>Choose an item from our catalogue below to view photos and additional rental information. Read through 
              our <a href="#delivery-info" className={[classes.textLink, classes.red].join(' ')} data-uk-scroll>delivery information</a> and <Link to="faqs" className={classes.textLink}>FAQ's </Link> 
              for additional details.
            </h2>
            <p className={classes.ldText}><LocationDisclaimer where="rentals" /></p>
            <p>Guests are responsible for damaged or lost equipment</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default rentals;