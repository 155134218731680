import React, {Component} from 'react';
import { Link } from "react-router-dom";
import classes from './Booqable.module.scss';
import Phone from "../../components/Shared/Phone";
import DeliveryInfo from "../../components/DeliveryInfo/DeliveryInfo";

const deliver = (<p className={classes.bold}>We offer free delivery on all orders!</p>)
// const pickup = (<p><strong>*Our team will pick everything up <span>the morning of your checkout date.</span></strong></p>)
const deliveryLg = (
<div className={[classes.sideDelivery, classes.dlg].join(' ')}>
  <h3><b>Delivery Information</b></h3>
  {deliver}
  <p><DeliveryInfo where="booq-bar" /></p>
  {/* {pickup} */}
</div>
);
// const deliverySm = (
// <div className={[classes.sideDelivery, classes.dsm].join(' ')}>
//   <ul className={classes.delivAcc} data-uk-accordion>
//     <li className={classes.cancelClass}>
//       <button className={[classes.delivTitle, "uk-accordion-title"].join(' ')}>
//         Delivery Information
//       </button>
      
//       <div className={[classes.delivContent, "uk-accordion-content"].join(' ')}>
//         {deliver}
//         <p><DeliveryInfo where="booq-barMobile" /></p>
//         {pickup}
//       </div>
//     </li>
//   </ul>
// </div>
// );


class Booqable extends Component {
  render() {
    return (
      <div id="fmbr-rentals" className={[classes.Booqable, classes.booqShow].join(' ')} style={{display: [this.props.displayType]}}>
        <div id="products" className={classes.insideXl}>
          <div className={classes.sideBar}> 
            <p className={classes.instr}><b>Enter your rental period to see availability for each item.</b></p>
            <p className={classes.top2}>If you have any questions about the products we offer, <Phone callText="call" /> us today!</p>
            <hr/>
            <div className={[classes.sideBarIn ,"booqable-sidebar"].join(' ')}></div>
            <hr id="delivery-info" />
            {deliveryLg}
            {/* {deliverySm} */}
            <hr/>
            <p className={classes.faq}>Still have questions?<br/> <Link to="faqs" className={classes.textLink}>Click here</Link> to see our FAQ's.</p>
          </div>
          
          <div className={[classes.productsGrid, "booqable-product-list"].join(' ')} data-show-search="true"> </div>
        </div>
      </div>
    );
  }
}
export default Booqable;