import React from "react";
import classes from "./Header.module.scss";
import Phone from "../Shared/Phone";
import Period from "../Shared/Period";
import { Link } from "react-router-dom";

const ctaBanner = () => {
  return (
    <div className={classes.ctaBanner}>
      <div className={classes.insideAlt}>
        <div className={classes.banner}>
          <p><Link to="/rentals">Book Online Now</Link> or Call Us Today: <br/><Phone /></p>
          <p className={classes.tpd}>*<Period /> Rental Period</p>
        </div>
      </div>
    </div>
  );
}
export default ctaBanner;