import React, {Component} from 'react';
import { withRouter, Route, Switch } from "react-router-dom";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import classes from './App.module.scss';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Phone from './components/Shared/Phone';
import Home from './hoc/Home/Home';
import Rentals from './hoc/Rentals/Rentals';
import Contact from './hoc/Contact/Contact';
import FAQs from './hoc/FAQs/FAQs';
import Booqable from './components/Booqable/Booqable';
import Music from "./components/Music/Music";


class App extends Component {
  render() {
    let routes = (
      <Switch>
        <Route exact={true} path="/" component={Home} />
        <Route path="/rentals" component={Rentals} />
        <Route path="/contact" component={Contact} />
        <Route path="/faqs" component={FAQs} />
      </Switch>
    );

    const pathName = window.location.pathname.replace('-','');
    const wrapClass = pathName.replace('/', '');
    
    const Booq = () => {
      if (window.location.pathname.match('/rentals')) {
        return (<Booqable displayType="block" />);
      }
      return (<Booqable displayType="none" />);
    }
    const SneakyBooq = withRouter(Booq);

    return (
      <div className={[classes.fmbrWrap, classes[wrapClass]].join(' ')}>
        <div className={[classes.announce, classes.closedXX, classes.open].join(' ')}>
          <p>NOW OPEN FOR THE SEASON!</p>
        </div>
        
        <div className={classes.excBar}>
          <p>* For customers at <span>The Dunes, The Indies,</span> and <span>Kiva Dunes:</span> please <Phone callText="call" /> for pricing and reservations.</p>
        </div>
        <div className={classes.excBar}>
          <p>* We cannot provide <span className={classes.bu}>tents</span> to the <span>Morgantown</span> area. All other rental items are still available.</p>
        </div>

        <div className={classes.bgWrap}>
          <Header />
          <Music />
          <main className={classes.Content}>
            {routes}
            
            <SneakyBooq />
          </main>
        </div>

        <Footer />
        <ScrollUpButton
          ContainerClassName={classes.ToTopBtn}
          TransitionClassName="MyOverRideTransitionedClass"
          style={{ fill: 'rgb(255,255,255)', backgroundColor: '#053d57', width: '28px', padding: '0px 5px 2px', bottom: '15px' }}
          EasingType='linear'
        />
      </div>
    );
  }
}

export default App;
