import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Phone from "../../components/Shared/Phone";
import Email from "../../components/Shared/Email";
import Social from "../../components/Social/Social";
import LocationDisclaimer from "../../components/LocationDisclaimer/LocationDisclaimer";
const classes = require('./Contact.module.scss');
// const image2 = require('../../assets/images/aa-loungers.jpg');
// const iconUmb = require('../../assets/images/summer-umbrella.png');

const contact = () => {
  return (
    <div className={classes.Contact}>
      <Helmet>
        <title>Beach Vacation Supplies for Ft Morgan, Alabama | Fort Morgan Beach Rentals</title>
        <meta name="description" content="Contact Fort Morgan Beach Rentals today to reserve your beach chairs, umbrellas, kayaks, and more! Everything you need for your beach vacation, plus free delivery!" />
      </Helmet>

      <div className={classes.ContactBody}>
        <div className={classes.med2Def}>
          <div className={classes.fullbox}>
            <div className={classes.titlebox}>
              <h1>Fort Morgan<br/> Beach Rentals</h1>
            </div>
            
            <div className={classes.insideAlt}>
              <div className={classes.box1}>
                <p><Phone /></p>
                <p><Email /></p>
                <Social />
              </div>
              <div className={classes.box2}>
                <h2>We've got you covered</h2>
                <p><Link to="/rentals" className={classes.textLink}>Click here </Link> to view our products and rental information, or to book online today! Contact us if you have any questions.</p>
                <p className={classes.ldText}><LocationDisclaimer where="contactLd" /></p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
export default contact;