import React from "react";
import Phone from "../Shared/Phone";
const classes = require('./LocationDisclaimer.module.scss');


const locationDisclaimer = (props) => {
  return (
    <span className={[classes.LocationDisclaimer, classes[props.where]].join(' ')}>
      *Availability and prices are for <span className={classes.und}>the Fort Morgan area only</span> unless otherwise noted.
      If you are looking for rentals in central Gulf Shores or the West Beach area, please <Phone callText="call" /> for information.
      <span className={classes.subLd}> Cancellations within 7-days of reservation will <span className={classes.und}>not</span> be eligible for refund.  
      We do <span className={classes.und}>not</span> offer refunds due to weather.</span>
    </span>
  );
}
export default locationDisclaimer;